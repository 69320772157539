.initial {
  text-align: center;
  margin-top: 20%;
  margin-left: 5%;
  margin-right: 5%;
  min-height: 200px;
  max-height: 100%; 
}
.texta{
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 5px;
}
.linkdue{
  color: black;
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
.sup{
  display:inline-flex;

 }