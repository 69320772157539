.content{
    margin-top: 5%;
    margin-left: 5%;
}
.content p {
    margin: 0;
  }

  .link{
    color: black;
    text-decoration:underline;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }